<template>
  <div class="template-wing-add">
    <div class="row">
      <div class="col-xl-3 left-card">
        <div class="card">
          <div class="card-body">
            <b-form-group>
              <h4 class="mb-4">Add a Wing</h4>
              <div class="brand mb-3">
                <label for="select-brand">Select Brand</label>
                <b-form-select
                  id="select-brand"
                  :class="{ 'is-invalid': $v.selectedBrand.$error }"
                  v-model="selectedBrand"
                >
                  <b-form-select-option :value="null">
                    Select a brand
                  </b-form-select-option>
                  <b-form-select-option value="addNewBrand">
                    Add a new brand
                  </b-form-select-option>
                  <template v-for="item in equipmentBrands">
                    <b-form-select-option :value="item.value" :key="item.value">
                      {{ item.value }}
                    </b-form-select-option>
                  </template>
                </b-form-select>
                <span
                  v-if="$v.selectedBrand.$error && !$v.selectedBrand.required"
                  class="help-block invalid-feedback"
                  >Brand is required</span
                >
                <b-input-group class="mt-2" v-if="!addNewBrandInput">
                  <b-form-input
                    v-model="brand"
                    placeholder="Add a brand"
                    :class="{ 'is-invalid': $v.brand.$error }"
                    :disabled="addNewBrandInput"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-button
                      size="sm"
                      text="Button"
                      variant="info"
                      @click="addBrand(brand)"
                      >&#10004;</b-button
                    >
                  </b-input-group-append>
                  <span
                    v-if="$v.brand.$error && !$v.brand.required"
                    class="help-block invalid-feedback"
                    >Brand is required</span
                  >
                </b-input-group>
                <b-alert v-if="brandAddSuccess" variant="success" show>
                  Brand added Successfully!
                </b-alert>
                <b-alert v-if="errorMessageBrand" show variant="danger">
                  Could not add, please retry
                </b-alert>
              </div>
              <!-- end brand -->

              <div class="select mb-3">
                <label for="select-model">Select Model</label>
                <b-form-select
                  id="select-model"
                  :class="{ 'is-invalid': $v.selectedModel.$error }"
                  v-model="selectedModel"
                >
                  <b-form-select-option :value="null"
                    >Select a model</b-form-select-option
                  >
                  <b-form-select-option
                    value="addNewModel"
                    :disabled="disableAddNewModel"
                    >Add a model</b-form-select-option
                  >
                  <template v-for="item in equipmentModelsTable">
                    <b-form-select-option :value="item.id" :key="item.id">
                      {{ item.name }}
                    </b-form-select-option>
                  </template>
                </b-form-select>
                <span
                  v-if="$v.selectedModel.$error && !$v.selectedModel.required"
                  class="help-block invalid-feedback"
                  >Model is required</span
                >
                <b-input-group class="mt-2" v-if="!addNewModelInput">
                  <b-form-input
                    v-model="model"
                    placeholder="Add a model"
                    :class="{ 'is-invalid': $v.model.$error }"
                    :disabled="addNewModelInput"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-button
                      size="sm"
                      text="Button"
                      variant="info"
                      @click="addModel(model)"
                      >&#10004;</b-button
                    >
                  </b-input-group-append>
                  <span
                    v-if="$v.model.$error && !$v.model.required"
                    class="help-block invalid-feedback"
                    >Model is required</span
                  >
                </b-input-group>
                <b-alert v-if="modelAddSuccess" variant="success" show>
                  Model added Successfully!
                </b-alert>
                <b-alert v-if="errorMessageModel" show variant="danger">
                  Could not add, please retry
                </b-alert>
              </div>
            </b-form-group>
            <!-- end select-model -->
          </div>
        </div>
      </div>
      <!-- left card -->

      <div class="col-xl-9 right-card">
        <div class="card">
          <div class="card-body">
            <b-form-group>
              <h5>Technical Information</h5>
              <b-row align-v="center">
                <b-col sm="6">
                  <b-row class="mb-3 mr-1 weight-min-max">
                    <label for="">Operational Weight Range (in Kgs)</label>
                    <b-col sm="5">
                      <b-input
                        placeholder="Min."
                        :class="{
                          'is-invalid': $v.operationalMinWeight.$error,
                        }"
                        v-model.number="operationalMinWeight"
                        type="number"
                      >
                      </b-input>
                      <span
                        v-if="
                          $v.operationalMinWeight.$error &&
                          !$v.operationalMinWeight.required
                        "
                        class="help-block invalid-feedback"
                        >Min weight is required</span
                      >
                      <span
                        v-if="
                          $v.operationalMinWeight.$error &&
                          !$v.operationalMinWeight.numeric
                        "
                        class="help-block invalid-feedback"
                        >Min weight must be numeric</span
                      >
                    </b-col>
                    <b-col sm="2" class="to-min-max">
                      <span>to</span>
                    </b-col>
                    <b-col sm="5">
                      <b-input
                        placeholder="Max."
                        :class="{
                          'is-invalid': $v.operationalMaxWeight.$error,
                        }"
                        v-model.number="operationalMaxWeight"
                        type="number"
                      >
                      </b-input>
                      <span
                        v-if="
                          $v.operationalMaxWeight.$error &&
                          !$v.operationalMaxWeight.required
                        "
                        class="help-block invalid-feedback"
                        >Max weight is required</span
                      >
                      <span
                        v-if="
                          $v.operationalMaxWeight.$error &&
                          !$v.operationalMaxWeight.numeric
                        "
                        class="help-block invalid-feedback"
                        >Max weight must be numeric</span
                      >
                    </b-col>
                  </b-row>
                </b-col>
                <!-- Operational Weight Range -->
                <b-col sm="6">
                  <div class="form-group mb-3 ml-1">
                    <label for="input-glider-weight"
                      >Glider Weight (in Kgs)</label
                    >
                    <b-input
                      id="input-glider-weight"
                      type="number"
                      placeholder="Enter glider weight"
                      :class="{ 'is-invalid': $v.gliderWeight.$error }"
                      v-model.number="gliderWeight"
                    ></b-input>
                    <span
                      v-if="$v.gliderWeight.$error && !$v.gliderWeight.required"
                      class="help-block invalid-feedback"
                      >Glider weight is required</span
                    >
                    <span
                      v-if="$v.gliderWeight.$error && !$v.gliderWeight.numeric"
                      class="help-block invalid-feedback"
                      >Glider weight must be numeric</span
                    >
                  </div>
                </b-col>
                <!-- Glider Weight -->

                <b-col sm="6">
                  <div class="form-group mb-3 mr-1">
                    <label for="select-en-certification"
                      >EN Certification</label
                    >
                    <b-form-select
                      id="select-en-certification"
                      :class="{ 'is-invalid': $v.selectedCertificate.$error }"
                      v-model="selectedCertificate"
                    >
                      <b-form-select-option value="null">
                        Select Certificate
                      </b-form-select-option>
                      <template v-for="item in enCertificates">
                        <b-form-select-option
                          :key="item.value"
                          :value="item.value"
                        >
                          {{ item.value }}
                        </b-form-select-option>
                      </template>
                    </b-form-select>
                    <span
                      v-if="
                        $v.selectedCertificate.$error &&
                        !$v.selectedCertificate.required
                      "
                      class="help-block invalid-feedback"
                      >EN Certificate is required</span
                    >
                  </div>
                </b-col>
                <!-- EN Certification -->

                <b-col sm="6">
                  <div class="form-group mb-3 ml-1">
                    <label for="input-wing-colour">Wing Colour</label>
                    <b-input
                      id="input-wing-colour"
                      placeholder="Eg: Blue/Orange"
                      :class="{ 'is-invalid': $v.wingColor.$error }"
                      v-model="wingColor"
                    ></b-input>
                    <span
                      v-if="$v.wingColor.$error && !$v.wingColor.required"
                      class="help-block invalid-feedback"
                      >Wing color is required</span
                    >
                  </div>
                </b-col>
                <!-- Wing Colour -->

                <b-col sm="6">
                  <div class="form-group mb-3 mr-1">
                    <label for="input-serial">Serial Number</label>
                    <b-input
                      id="input-serial"
                      placeholder="Enter equipment serial number"
                      :class="{ 'is-invalid': $v.serialNo.$error }"
                      v-model="serialNo"
                    ></b-input>
                    <span
                      v-if="$v.serialNo.$error && !$v.serialNo.required"
                      class="help-block invalid-feedback"
                      >Serial number is required</span
                    >
                  </div>
                </b-col>
                <!-- Equipment Serial number -->

                <b-col sm="6">
                  <div class="form-group mb-3 ml-1">
                    <label for="select-manufacture-year"
                      >Year of Manufacture</label
                    >
                    <b-form-select
                      id="select-manufacture-year"
                      :class="{ 'is-invalid': $v.manufactureYear.$error }"
                      v-model="manufactureYear"
                    >
                      <b-form-select-option value="null"
                        >Select one</b-form-select-option
                      >
                      <template v-for="year in yearList">
                        <b-form-select-option :value="year" :key="year">
                          {{ year }}
                        </b-form-select-option>
                      </template>
                    </b-form-select>
                    <span
                      v-if="
                        $v.manufactureYear.$error &&
                        !$v.manufactureYear.required
                      "
                      class="help-block invalid-feedback"
                      >Manufacture year is required</span
                    >
                  </div>
                </b-col>
                <!-- Year of manufacture -->
              </b-row>
            </b-form-group>
            <b-form-group>
              <h5>Operator Linking</h5>
              <b-row align-v="center">
                <b-col sm="6">
                  <div class="form-group mb-3 mr-1">
                    <label for="select-zone">Zone</label>
                    <b-form-select
                      id="select-zone"
                      :class="{ 'is-invalid': $v.selectedZone.$error }"
                      v-model="selectedZone"
                    >
                      <b-form-select-option value="null">
                        Select Zone
                      </b-form-select-option>
                      <template v-for="item in zoneTable">
                        <b-form-select-option :key="item.id" :value="item.id">
                          {{ item.name }}
                        </b-form-select-option>
                      </template>
                    </b-form-select>
                    <span
                      v-if="$v.selectedZone.$error && !$v.selectedZone.required"
                      class="help-block invalid-feedback"
                      >Zone is required</span
                    >
                  </div>
                </b-col>
                <!-- ZONE -->

                <b-col sm="6">
                  <div class="form-group mb-3 mr-1">
                    <label for="select-operator">Operator</label>
                    <b-form-select
                      id="select-operator"
                      :class="{ 'is-invalid': $v.selectedOperator.$error }"
                      v-model="selectedOperator"
                    >
                      <b-form-select-option value="null">
                        Select Operator
                      </b-form-select-option>
                      <template v-for="item in newFilteredOperatorList">
                        <b-form-select-option
                          :key="item.operator_id"
                          :value="item.operator_id"
                        >
                          {{ item.company_name }}
                        </b-form-select-option>
                      </template>
                    </b-form-select>
                    <span
                      v-if="
                        $v.selectedOperator.$error &&
                        !$v.selectedOperator.required
                      "
                      class="help-block invalid-feedback"
                      >Operator is required</span
                    >
                  </div>
                </b-col>
                <!-- OPERATOR -->
              </b-row>
            </b-form-group>
            <b-row align-v="center">
              <b-col sm="12" class="btn-submit">
                <b-button @click="handleSubmit">Submit</b-button>
              </b-col>
              <!-- Submit button -->
            </b-row>
            <b-alert v-if="wingAddSuccess" variant="success" show>
              Form Submitted Successfully!
            </b-alert>
            <b-alert v-if="errorMessageWing" show variant="danger">
              Error: Could not add/update
            </b-alert>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->
      </div>
      <!-- right card -->
    </div>
    <!-- end row -->
  </div>
  <!-- end template-wing-add -->
</template>

<script>
import {
  GET_EQUIPMENT_BRANDS,
  GET_EQUIPMENT_MODELS,
  GET_EN_CERTIFICATES,
  GET_ZONES,
  GET_OPERATORS_ADD_PILOT,
  GET_WING_PK,
} from "@/graphql/queries/queries";
import {
  ADD_EQUIPMENT_BRAND,
  ADD_EQUIPMENT_MODEL,
  ADD_WING,
  UPDATE_WING,
} from "@/graphql/mutations/mutations";
import { required, numeric } from "vuelidate/lib/validators";

const BRANDS_ATTRIBUTE = "equipment_brands";
const MODELS_ATTRIBUTE = "equipment_models";
const OPERATORS_ATTRIBUTE = "operators";
const ZONES_ATTRIBUTE = "zone";
const EN_CERTIFICATE_ATTRIBUTE = "en_certification_type";
const GET_WING_PK_ATTRIBUTE = "wings_by_pk";

export default {
  name: "addWing",
  data() {
    return {
      wingID: this.$route.params.wingID,
      wingData: {},
      equipmentBrands: [],
      equipmentModels: [],
      equipmentModelsTable: [],
      zoneTable: [],
      operatorsTable: [],
      newFilteredOperatorList: [],
      enCertificates: [],
      brand: null,
      model: null,
      addNewBrandInput: true,
      addNewModelInput: true,
      selectedBrand: null,
      selectedModel: null,
      selectedCertificate: null,
      selectedZone: null,
      selectedOperator: null,
      serialNo: "",
      wingColor: "",
      gliderWeight: null,
      operationalMinWeight: null,
      operationalMaxWeight: null,
      manufactureYear: null,
      yearList: [],
      brandAddSuccess: false,
      modelAddSuccess: false,
      disableAddNewModel: true,
      errorMessageBrand: false,
      errorMessageModel: false,
      wingAddSuccess: false,
      errorMessageWing: false,
    };
  },
  validations: {
    selectedBrand: {
      required,
    },
    brand: {
      required: function () {
        return this.addNewBrandInput || !!this.brand;
      },
    },
    selectedModel: {
      required,
    },
    model: {
      required: function () {
        return this.addNewModelInput || !!this.model;
      },
    },
    selectedCertificate: {
      required,
    },
    selectedZone: {
      required,
    },
    selectedOperator: {
      required,
    },
    serialNo: {
      required,
    },
    wingColor: {
      required,
    },
    gliderWeight: {
      required,
      numeric,
    },
    operationalMinWeight: {
      required,
      numeric,
    },
    operationalMaxWeight: {
      required,
      numeric,
    },
    manufactureYear: {
      required,
    },
    form: [
      "selectedBrand",
      "brand",
      "model",
      "selectedModel",
      "selectedCertificate",
      "selectedZone",
      "selectedOperator",
      "serialNo",
      "wingColor",
      "gliderWeight",
      "operationalMinWeight",
      "operationalMaxWeight",
      "manufactureYear",
    ],
  },
  watch: {
    wingData() {
      (this.selectedCertificate = this.wingData.en_certification),
        (this.gliderWeight = this.wingData.glider_weight),
        (this.operationalMinWeight = this.wingData.operational_weight_min),
        (this.operationalMaxWeight = this.wingData.operational_weight_max),
        (this.serialNo = this.wingData.serial_no),
        (this.wingColor = this.wingData.wing_color),
        (this.manufactureYear = this.wingData.year_mfg),
        (this.selectedBrand = this.wingData.equipment_model.brand),
        (this.selectedZone = this.wingData.operator.zone.id),
        (this.selectedOperator = this.wingData.operator.operator_id);
      this.filterModels();
      this.filterOperatorsTable();
    },
    selectedBrand() {
      this.filterModels();
    },
    selectedModel() {
      this.addNewModelInput = this.selectedModel !== "addNewModel";
    },
    selectedZone() {
      this.filterOperatorsTable();
    },
  },
  mounted() {
    for (let i = 1970; i <= new Date().getFullYear(); i++) {
      this.yearList.unshift(i);
    }
    this.$apollo.queries.wingData.skip = true;
    this.loadEditDatas();
  },
  methods: {
    async handleSubmit() {
      this.$v.form.$touch();
      let isValid = !this.$v.form.$invalid;
      if (isValid) {
        this.errorMessageWing = false;
        const payLoad = {
          en_certification: this.selectedCertificate,
          glider_weight: this.gliderWeight,
          operational_weight_min: this.operationalMinWeight,
          operational_weight_max: this.operationalMaxWeight,
          serial_no: this.serialNo,
          wing_color: this.wingColor,
          year_mfg: this.manufactureYear,
          model_id: this.selectedModel,
          operator_id: this.selectedOperator,
        };

        if (this.wingID) {
          // Update wing function
          try {
            const updateWingMutation = await this.$apollo.mutate({
              mutation: UPDATE_WING,
              variables: {
                id: this.wingID,
                input: payLoad,
              },
            });
            if (updateWingMutation) {
              this.wingAddSuccess = true;
              this.formReset();
              console.log("Wing updated successfully", updateWingMutation);
              setTimeout(() => {
                this.$router.push({ name: "wing" });
              }, 3000);
            }
          } catch (error) {
            this.errorMessageWing = true;
            console.error(error);
          }
          this.$nextTick(() => {
            this.$v.form.$reset();
          });
        } else {
          // Add wing function
          try {
            const addWingMutation = await this.$apollo.mutate({
              mutation: ADD_WING,
              variables: {
                input: payLoad,
              },
            });
            if (addWingMutation) {
              this.wingAddSuccess = true;
              this.formReset();
              console.log("Wing added successfully", addWingMutation);
              setTimeout(() => {
                this.$router.push({ name: "wing" });
              }, 3000);
            }
          } catch (error) {
            this.errorMessageWing = true;
            console.error(error);
          }
          this.$nextTick(() => {
            this.$v.form.$reset();
          });
        }
      }
    },
    formReset() {
      (this.selectedBrand = null),
        (this.selectedZone = null),
        (this.selectedCertificate = null),
        (this.gliderWeight = null),
        (this.operationalMinWeight = null),
        (this.operationalMaxWeight = null),
        (this.serialNo = ""),
        (this.wingColor = ""),
        (this.manufactureYear = null),
        (this.selectedModel = null),
        (this.selectedOperator = null);
    },
    filterModels() {
      this.selectedModel = null;
      this.addNewBrandInput = true;
      this.addNewModelInput = true;
      this.disableAddNewModel = true;
      if (this.selectedBrand !== null) {
        this.disableAddNewModel = false;
      }
      if (this.selectedBrand === "addNewBrand") {
        this.addNewBrandInput = false;
      }
      this.equipmentModelsTable = this.equipmentModels.filter(
        (a) => a.brand === this.selectedBrand
      );
      if (this.wingID) {
        // for wing update
        this.selectedModel = this.wingData.equipment_model.id;
      }
    },
    filterOperatorsTable() {
      const newOperatorsTable = this.operatorsTable.filter(
        (a) => a.zone.id === this.selectedZone
      );
      this.newFilteredOperatorList = newOperatorsTable;
      if (this.wingID) {
        // for wing update
        this.selectedOperator = this.wingData.operator.operator_id;
      }
    },
    async addBrand(brandName) {
      this.errorMessageBrand = false;
      this.$v.form.brand.$touch();
      let isValid = !this.$v.form.brand.$invalid;
      if (isValid) {
        try {
          const addBrand = await this.$apollo.mutate({
            mutation: ADD_EQUIPMENT_BRAND,
            variables: {
              input: { value: brandName },
            },
          });
          if (addBrand) {
            this.brandAddSuccess = true;
            this.$apollo.queries.equipmentBrands.refetch();
            this.selectedBrand = brandName;
            this.brand = "";
            console.log("brand added successfully", addBrand);
          }
        } catch (error) {
          this.errorMessageBrand = true;
          console.error(error);
        }
      }
    },
    async addModel(modelName) {
      this.errorMessageModel = false;
      this.$v.form.model.$touch();
      let isValid = !this.$v.form.model.$invalid;
      if (isValid) {
        try {
          const addModel = await this.$apollo.mutate({
            mutation: ADD_EQUIPMENT_MODEL,
            variables: {
              input: {
                name: modelName,
                brand: this.selectedBrand,
                equipment_type: "wing",
              },
            },
          });
          if (addModel) {
            this.modelAddSuccess = true;
            const refreshModelList =
              await this.$apollo.queries.equipmentModels.refetch();
            if (refreshModelList) {
              this.filterModels();
              const addedModel = await this.equipmentModelsTable.find(
                (a) => a.name === modelName
              );
              if (addedModel) {
                this.selectedModel = addedModel.id;
                this.model = "";
              }
            }
            console.log("Model added successfully", addModel);
          }
        } catch (error) {
          this.errorMessageModel = true;
          console.error(error);
        }
      }
    },
    async loadEditDatas() {
      if (this.wingID) {
        let modelsDataReady =
          await this.$apollo.queries.equipmentModels.refetch();
        let operatorsDataReady =
          await this.$apollo.queries.operatorsTable.refetch();
        if (modelsDataReady && operatorsDataReady) {
          this.$apollo.queries.wingData.skip = false;
          this.$apollo.queries.wingData.refetch();
        }
      }
    },
  },
  apollo: {
    equipmentBrands: {
      // graphql query
      query: GET_EQUIPMENT_BRANDS,
      update(data) {
        return data[BRANDS_ATTRIBUTE];
      },
      error(error) {
        this.error = JSON.stringify(error.message);
        console.error(this.error);
      },
    },
    equipmentModels: {
      // graphql query
      query: GET_EQUIPMENT_MODELS,
      update(data) {
        return data[MODELS_ATTRIBUTE];
      },
      error(error) {
        this.error = JSON.stringify(error.message);
        console.error(this.error);
      },
    },
    enCertificates: {
      // graphql query
      query: GET_EN_CERTIFICATES,
      update(data) {
        return data[EN_CERTIFICATE_ATTRIBUTE];
      },
      error(error) {
        this.error = JSON.stringify(error.message);
        console.error(this.error);
      },
    },
    operatorsTable: {
      // graphql query
      query: GET_OPERATORS_ADD_PILOT,
      update(data) {
        return data[OPERATORS_ATTRIBUTE];
      },
      error(error) {
        this.error = JSON.stringify(error.message);
        console.error(this.error);
      },
    },
    zoneTable: {
      // graphql query
      query: GET_ZONES,
      update(data) {
        return data[ZONES_ATTRIBUTE];
      },
      error(error) {
        this.error = JSON.stringify(error.message);
        console.error(this.error);
      },
    },
    wingData: {
      // graphql query
      query: GET_WING_PK,
      variables() {
        return {
          id: this.wingID,
        };
      },
      skip: true,
      update(data) {
        return data[GET_WING_PK_ATTRIBUTE];
      },
      error(error) {
        this.error = JSON.stringify(error.message);
        console.error(this.error);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.template-wing-add {
  padding-top: 24px;

  .left-card {
    h5 {
      text-align: center;
    }
  }

  .right-card {
    .weight-min-max {
      margin-left: 0;

      .to-min-max {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .btn-submit {
      text-align: right;

      button {
        background: rgb(114, 124, 245);
        border: none;
      }
    }
  }
}
</style>
